export default {
  tac: `
  <style>
    h2 {
      margin-block-start: 0.3em;
    }
    .tac-content a {
      color: #e9425e;
      margin-top: 30px;
    }
    .tac-content div {
      margin-bottom: 10px;
      margin-top: 5px;
    }
    .tac-content ol {
      list-style-position: inside;
      padding-inline-start: unset;
      counter-reset: item;
      margin-block-start: 5px;
      text-align: justify;
    }
    .tac-content {
      font-size: 0.8rem;
    }
    .tac-content .title {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content ol.a > li::marker {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content .last-update {
      font-size: 1rem;
      text-align: left;
      margin-top: 30px;
    }
  </style>
  <h2>PXL Vision AG User agreement for end users of the WebApp „Daego“</h2>
  <div class="tac-content">
    <ol class="a">
      <li>
        <span class="title">Service description</span>
        <div>
          PXL Vision AG (hereinafter referred to as "PXL", "we" or "us") provides you with the web
          application "Daego" (hereinafter referred to as "Product"), a portal solution for use on
          your compatible device as well as server services, through which you can use your
          personal ID-document issued by an official authority (e.g. passport, identity card,
          foreigner's identity card, etc.) and which then compares the scanned data with a selfie
          created via the WebApp and, if successful, generates a digital proof of identity for use
          by transaction partners.
        </div>
        <div>
          The digital proof of identity enables you to carry out a transaction with corresponding
          providers (e.g. mobile phone providers, banks, web stores, etc. - hereinafter referred
          to as "transaction partners").
        </div>
        <div>
          In order to carry out a transaction, the transaction partner (e.g. when concluding an
          online contract) will ask you to either click on a link to the WebApp associated with a
          transaction code or, after directly entering the WebApp"s web address and scanning the
          documents as described below, to enter a transaction code provided by that partner or to
          scan QR Code.
        </div>
        <div>
          The WebApp identifies you as the owner of the scanned official identification document
          within the transaction. For verification by the transaction partner, the collected data
          is additionally transmitted to the transaction partner when the transaction is
          triggered. The transaction partner saves it according to his data protection guidelines.
        </div>
        <div>
          The verification of your identification document with the recorded selfie is carried out
          as follows:
        </div>
        <div>
          By focusing and triggering the ID document with the camera of the end device, the WebApp
          photographs and scans your ID document and uses text recognition to identify the
          personal data contained therein.
        </div>
        <div>
          In addition, the complete photo of the ID document is captured with the additional
          security features (such as hologram and design elements).
        </div>
        <div>
          After scanning the document, you will be asked to take a short video of yourself using
          the camera connected to the end device.
        </div>
        <div>
          The data, photos and video collected by the web app are transmitted from your end device
          via a secure connection in real time to the SOC 2 certified servers located in
          Switzerland, where they are processed and stored. On PXL's systems, the collected data
          is then compared against each other and the collected photo is checked against the
          selfie video in terms of biometric data. Furthermore, the photo of the ID document is
          verified. Once the identity verification process is completed, you will be asked for
          your explicit consent that the identification data and the image files shall be
          transferred to the transaction partner and after your consent the data is sent to the
          transaction partner who provided you with the link to the web application or the
          transaction ID or QR code to execute the identification process, to confirm the identity
          and, if necessary, to store it in your customer account there.
        </div>
        <div>
          Use of the WebApp and the identification process in accordance with the agreement is
          free of charge.
        </div>
        <div>
          Use of the WebApp on a cell phone may result in mobile data connections, which are
          billed or volume billed according to your network operator's data tariff.
        </div>
      </li>
      <li>
        <span class="title">Conditions of use</span>
        <div>
          To use the identification and verification functionality of the WebApp, a transaction
          code or a link or QR Code must be provided by a transaction partner.
        </div>
        <div>
          The WebApp is not linked to the creation of a permanent user account with PXL,
          verification is only performed once for the assigned transaction code. If you identify
          yourself again via the WebApp for another service, the scan and verification procedure
          have to be run again.
        </div>
        <div>
          Unless you explicitly allow PXL to use the collected identification data for the purpose
          of scientific development and improvement of the machine learning process underlying the
          identification procedure, your identification data will be deleted after transfer to the
          transaction partner. The transaction partner stores this data in accordance with his
          data protection guidelines, of which you should take note separately.
        </div>
        <div>
          If you do not fully agree with these terms and conditions, please do not continue the
          identification process, and ask the transaction partner for alternative identification
          options.
        </div>
      </li>
      <li>
        <span class="title">Rights to the selfie video</span>
        <div>
          To use the product, you are required to provide your personal data and the selfie video
          you have created. You hereby grant PXL for the creation and maintenance of the digital
          identity, for the execution of the verification process and the improvement of the
          technical processes (provided that you have consented thereto) a temporally and locally
          unlimited, free right of use of the video created by you and waive your rights to your
          own image within the scope of the necessary processing of the image data. You may revoke
          this consent at any time by sending an E-Mail to
          <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>.
        </div>
      </li>
      <li>
        <span class="title">Exclusion of warranty</span>
        <div>
          The WebApp and related services provided free of charge are provided "as is", that is,
          with all faults, if any, and without warranty of any kind. PXL hereby excludes any
          warranty.
        </div>
        <div>
          A compatibility with the operating systems and end devices used by the user is not
          guaranteed at any time.
        </div>
        <div>
          Accordingly, the use of the WebApp to verify the user's identity is at the user's own
          risk, nor is the successful use of the App to initiate a transaction initiated via the
          App warranted.
        </div>
      </li>
      <li>
        <span class="title">Liability of PXL</span>
        <div>
          PXL is only liable for arising damages, as far as these are based on a violation of an
          essential contractual obligation or on an intentional or grossly negligent behavior by
          PXL, its legal representatives or vicarious agents. If an essential contractual
          obligation is violated by slight negligence, the liability of PXL is limited to the
          foreseeable damage typical for the contract. An essential contractual obligation is
          given with obligations, the fulfillment of which makes the proper execution of the
          contract possible in the first place or on the compliance of which the customer relied
          and was allowed to rely.
        </div>
        <div>
          Any further liability including liability for indirect damages, consequential damages
          and loss of profit is excluded.
        </div>
      </li>
      <li>
        <span class="title">Data protection</span>
        <div>
          PXL is committed to protecting your privacy. It is our goal to make your use of our
          products and services as user-friendly, reliable, and secure as possible, while
          protecting your personal information as defined in PXL's Privacy Policy ("Privacy
          Policy"). For information about our privacy practices and our privacy policy, please
          refer to the
          <u>Privacy Policy</u>.
        </div>
        <div>
          Before using the verification process, we ask for your express consent that PXL may
          collect and process personal data, including special categories of personal data as
          defined in Article 9 paragraph 1 of the Basic Data Protection Regulation or Article 3
          lit. c of the Swiss Data Protection Act, via WebApp, such as biometric data.
        </div>
        <div>
          We also collect data on identification documents that the application could not read,
          photos and videos where human verification and the application yield different results,
          and data on successful verifications.
        </div>
        <div>
          For all further and detailed information please refer to the Privacy Policy that
          herewith is part of your usage agreement.
        </div>
        <div>
          Please note that separate data protection regulations apply to the download of software
          via the App Store (e.g. Apple and Google). Please inform yourself about these
          regulations when using the App Store. PXL is not responsible for the data processing of
          these providers.
        </div>
      </li>
      <li>
        <span class="title">Other regulations.</span>
        <div>
          This agreement shall be governed by Swiss law to the exclusion of the United Nations
          Convention on Contracts for the International Sale of Goods of April 11, 1980
          (UN Sales Convention).
        </div>
        <div>
          Place of jurisdiction for all disputes between PXL and its users is Zurich.
        </div>
        <div>
          PXL may, however, also sue the user at his domicile or residence.
        </div>
        <div>
          PXL may transfer its rights and obligations under the contract in whole or in part to a
          third party, if the fulfillment of the contract is not affected or endangered thereby
          and no predominant legitimate interests of the user conflict with this. These Terms of
          Use are valid at the time of the respective use of the WebApp in their current version.
        </div>
        <div>
          If individual provisions of this agreement are or become invalid, they shall be replaced
          by a provision that comes as close as possible to the economic purpose of the invalid
          provision. The validity of the remaining provisions shall not be affected thereby.
        </div>
      </li>
      <li>
        <span class="title">Contact with PXL:</span>
        <p>Email:
          <a href="mailto:info@pxl-vision.com">info@pxl-vision.com</a> or
          <a href="mailto:support@pxl-vision.com">support@pxl-vision.com</a>
        </p>
        <p>Address: PXL Vision AG, Mühlebachstrasse 164, CH-8008 Zurich, Switzerland</p>
        <p>Phone: +41 44 295 10 40</p>
        <p>Contact form: <a href="https://pxl-vision.com/contactus/">https://pxl-vision.com/contactus/</a></p>
      </li>
    </ol>
    <p class="last-update">© PXL Vision AG, 09/2020</p>
  </div>`,
  privacyPolicy: `
    <style>
    .terms-subtitle {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content {
      font-size: 0.8rem;
    }
    .tac-content a {
      color: #e9425e;
      margin-top: 30px;
    }
    .tac-content div {
      margin-bottom: 10px;
    }
    .tac-content > ol> li > div:nth-child(2) {
      margin-top: 10px;
    }
    .tac-content ol {
      list-style-position: inside;
      padding-inline-start: unset;
      counter-reset: item;
      margin-block-start: 5px;
      text-align: justify;
      list-style-type: upper-roman;
    }
    .tac-content ol li::marker {
      font-weight: bold;
    }
    .tac-content ol.b {
      list-style-type: lower-alpha;
    }
    .tac-content ol.b > li,
    .tac-content ol.d > li,
    .tac-content ol.k > li {
      margin-left: 10px;
    }
    .tac-content .lower-alpha {
      list-style-type: lower-alpha;
    }
    .tac-content .dash {
      list-style-type: none;
      padding: 0;
    }
    .tac-content .dash li {
      margin: 3px 0 3px 10px;
    }
    .tac-content .dash li::before {
      content: "- ";
      font-weight: bold;
    }
    .tac-content ol.d {
      list-style-type: decimal;
    }
    .tac-content ol.d .subheading {
      font-weight: bold;
    }
    .tac-content ol.d > li p {
      margin: 3px 0 3px 10px;
    }
    .tac-content .no-bullets {
      list-style-type: none;
      padding: 0;
    }
    .tac-content .no-bullets > li {
      padding: 3px 0 3px 10px;
    }
    .tac-content ol.k {
      list-style-type: none;
    }
    .tac-content ol.k li {
      margin-left: 10px;
    }
    .tac-content ol.k subheading {
      font-weight: bold;
    }
    .tac-content div {
      padding-left: 10px;
    }
    .tac-content.m > li {
      margin-bottom: 10px;
    }
    .tac-content ol.k > li:before {
      font-weight: bold;
      content: "(" counter(item) ") ";
      counter-increment: item;
    }
    .tac-content ol.a > li::marker {
      font-weight: bold;
      font-size: 1.1rem;
    }
    .tac-content .title {
      font-weight: bold;
      font-size: 1.1rem;
    }
    ol.b > span {
      margin-bottom: 10px;
    }
    .tac-content > ol > li {
      margin-bottom: 30px;
    }
    </style>
      <div class="tac-content">
      <div class="terms-subtitle">
        <p>
          Data protection conditions PXL Vision AG for use of the Daego® Mobile App and the
          Daego® WebApp
        </p>
      </div>
      <ol class="a">
        <li>
          <span class="title">The product</span>
          <div>
            PXL Vision's Daego® product provides a digital identification solution for PXL
            independent transaction partners to verify an ID document against its security
            features and data, and to match the photo on the ID document with a user-captured
            selfie video. The verified result can then be used by transaction partners to verify
            the identity at a required identity check.
          </div>
          <div>
            Daego® can be used in two different ways: As part of the Daego® Mobile App
            for installation from a smartphone and via the Daego® WebApp for use via the
            Internet browser.
          </div>
          <div>
            Within the framework of the mobile app, identification is only necessary once and the
            app can be used for identification with several transaction partners because the
            identification data and the validation result are stored in the app. With the web app,
            an identity card comparison must be carried out for each transaction.
          </div>
        </li>
        <li>
          <span class="title">The secure processing of data</span>
          <div>
            The verification of identification documents is necessarily connected with the
            collection and processing of personal data from the scanned document and the video
            from the identification process. To carry out the comparison of the photos, personal
            data that are particularly worthy of protection as special categories of personal
            data (biometric data of the user's face) are also collected and processed.
          </div>
          <div>
            The processes used by PXL to process this data are subject to strict data protection
            regulations and are secured by correspondingly secure technical and organizational
            measures. The servers of PXL are located exclusively in Switzerland, data will not be
            passed on to third parties or other countries (except for the transmission to the
              transaction partners specifically named in the identification).
          </div>
        </li>
        <li>
          <span class="title">Further processing for other purposes (development purposes) in
          accordance with Art. 13 para. 3 DSGVO and your separate consent in accordance with
          Art. 9 para. 2 DSGVO</span>
          <div>
            The data collected and processed as part of the identification and verification
            process are generally only processed to provide identification in the respective
            individual case and are not used for other purposes or even transferred or sold
            to third parties.
          </div>
          <div>
            The verification process, however, is based on complex analysis algorithms that
            require continuous improvement in order to provide a reliable analysis result. The
            processing of this data for this purpose, which deviates from the pure identification
            service, is, according to data protection laws, dependent on the express consent of
            the user concerned. PXL therefore asks the user for this consent before using the
            product. If the user agrees, the collected data will be stored on separate servers,
            also exclusively located in Switzerland, and will be used to improve the algorithms.
            Naturally, strict technical and organizational protective measures apply here as well.
            The employees entrusted with the analysis of the data are separately committed to the
            strict observance of data protection and confidentiality.
          </div>
          <div>
            To ensure fair and transparent processing, we hereby inform you in accordance with
            Art. 13 Para. 2 DSGVO about the processing of this data within the scope of
            this purpose:
            <ol class="b">
              <li>
                <span class="subheading">Storage duration and criteria for storage duration</span>
                <div>
                  In principle, the effective use of machine learning processes requires
                  algorithms with a database that is as comprehensive as possible. Therefore,
                  the storage of the data is basically necessary and reasonable. Data will be
                  deleted if you request deletion of your data or object to its use, which will
                  result in immediate deletion.
                </div>
              </li>
              <li>
                <span class="subheading">Right of objection at any time</span>
                <div>
                  You can request your objection to the use of your data for the machine learning
                  processes at any time at
                  <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>. This does
                  not affect the lawfulness of the processing of your data based on
                  your consent until revocation.
                </div>
              </li>
              <li>
                <span class="subheading">Right of appeal to a supervisory authority</span>
                <div>
                  You have the right to complain to a supervisory authority about the processing
                  of your data, for further information see below under IV.9.d.
                </div>
              </li>
              <li>
                <span class="subheading">Mandatory by law or contract</span>
                <div>
                  The collection and processing of your data within the framework of Machine
                  Learning is exclusively based on your voluntary consent.
                </div>
              </li>
              <li>
                <span class="subheading"> Automatic decision making and profiling </span>
                <div>
                  The machine learning processes and the associated evaluation of the biometric
                  data for the purpose of improving identity verification are not used for
                  profiling purposes or via profiling. PXL's biometric and identification data
                  will not be used to evaluate the personal aspects of a person that would analyze
                  or categorize you as a data subject in terms of personal characteristics, age,
                  interests, health, economic situation, whereabouts of relocation, or behavior
                  that could cause legal prejudice to you. The data collected and processed with
                  your consent are used exclusively for the algorithmic comparison of your face
                  with the data held in the identification document. Within the framework of an
                  identity check via the app, an automatic decision on the verification will be
                  made if the comparison is positive, but you will voluntarily initiate the
                  verification process for use with a transaction partner of your choice.
                  Especially to make this automatic decision as reliable as possible, the use of
                  biometric data exclusively for this purpose within the machine learning
                  processes is of particular importance. Your data will not be processed for any
                  other purpose and the automatic decision during verification is based solely on
                  the algorithmic comparison of your biometric facial data without any other
                  evaluation of the same.
                </div>
              </li>
            </ol>
          </div>
        </li>
        <div>
          <b>
            PXL thanks you for your willingness to support the improvement of the processes with
            your consent. Of course, you can object to your consent at any time by sending
            an e-mail to
            <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>.
          </b>
          The data will then be deleted immediately.
        </div>
        <li>
          <span class="title">Data protection information obligations </span>
          <div>
            <ol class="d">
              <li>
                <span class="subheading">Data protection law responsible processor</span>
                <ul class="no-bullets">
                  <li>PXL Vision AG</li>
                  <li>Mühlebachstrasse 164</li>
                  <li>8008 Zurich, Switzerland</li>
                </ul>
              </li>
              <li>
                <span class="subheading">Contact details of the data protection officer </span>
                <p>
                  <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>
                </p>
              </li>
              <li>
                <span class="subheading">Categories of personal data processed </span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">When the Mobile App is called, we collect and process
                    the following usage data:</span>
                    <ul class="dash">
                      <li>UDID of the device</li>
                      <li>the type of the device model</li>
                      <li>Operating system</li>
                      <li>Browser version</li>
                    </ul>
                  </li>
                  <li>
                    <span class="subheading">When you call up the WebApp, we collect and process
                    the following usage data: </span>
                    <ul class="dash">
                      <li>UDID of the device</li>
                      <li>the type of the device model</li>
                      <li>Operating system</li>
                      <li>Browser version</li>
                      <li>Cookies (session Cookie for load balancing)</li>
                    </ul>
                  </li>
                  <li>
                    <span class="subheading">If you initiate and carry out a verification process,
                      we collect and process the following personal data (identification data):
                    </span>
                    <ol class="k">
                      <li>
                        <span class="subheading">master data: Identity card data </span>
                        <div>
                          Surname, maiden name, first names, doctorate, date of birth, place of
                          birth, address, nationality, type of document, last day of validity,
                          service and card-specific identifiers, country code, indication whether
                          a certain age is exceeded or not, indication whether a place of
                          residence corresponds to the queried place of residence, and
                          church-order name, artist name, ID card number
                        </div>
                      </li>
                      <li>
                        <span class="subheading">Image files</span>
                        <div>
                          We collect a photo of both sides of your ID document, your personal
                          photo from your ID or (if applicable and available) from the NFC data
                          of your ID document and the selfie video of your face
                        </div>
                      </li>
                      <li>
                        <span class="subheading">Special types of personal data </span>
                        <div>
                          Biometric data are analyzed from the image data.
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">If you contact the contact address specified in the
                    Mobile App/Web App as part of a user inquiry: </span>
                    <ol class="k">
                      <li>
                        <span class="subheading">user data: </span>
                        <div>Your name and address, as well as e-mail address or telephone number,
                        if applicable, which you provide in the user inquiry.</div>
                      </li>
                      <li>
                        <span class="subheading">Identification data given above, if applicable, </span>
                        <div>if this is necessary to process the user request.</div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">Other</span>
                    <div>
                      The App includes tools that report system failures for recovery and
                      improvement of the user experience (browser and operating system may be
                      transferred)
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">the purposes for which the personal data are to be
                  processed and the legal basis for the processing</span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">In the following cases we collect and process data
                      in the context of a legitimate interest according to Art. 6 para. 1 lit f)
                      GDPR:
                    </span>
                    <ol class="k m">
                      <li> Usage / metadata to improve the App/WebApp user experience</li>
                      <li>
                        <span>Usage data for the correction of errors in the software or processes</span>
                      </li>
                      <li>
                        <span>Anonymization of usage data for statistical purposes </span>
                      </li>
                      <li>
                        <span>
                          Identification data and photo files for the purpose of error checking
                          after a completed verification and transaction. In the case of an
                          unsuccessful verification, data will also be stored to be able to
                          deal with any subsequent errors or complaints.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">
                      In the following cases we collect and process data within the scope of a
                      consent according to article 6 paragraph 1 lit. a) or article 9 paragraph
                      2 lit. a) GDPR
                    </span>
                    <ol class="k m">
                      <li>
                        <span>
                          Identification data and biometric data generated from identity card
                          photo and selfie video to perform the identification and verification
                          process
                        </span>
                      </li>
                      <li>
                        <span>
                          Identification data and biometric data generated from identity card
                          photo and selfie video to improve identification procedures in the
                          context of machine learning and artificial intelligence processes. For
                          more detailed relevant information on further processing for this
                          purpose, please refer to the detailed explanation above in section
                          III.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">In the following cases we collect and process data for the provision of the contractually agreed service in accordance with Art. 6 Par. 1 lit. b) GDPR</span>
                    <ol class="k m">
                      <li>
                        <span>Collection, processing, and transmission of the identification data for comparison with the data set available at the transaction partner. If verification is successful, the data is transmitted to the transaction partner via a secure connection. </span>
                      </li>
                      <li>
                        <span>Collection, processing and transmission of the photos and the selfie videos for the purpose of matching the ID photo and the person photographed during use, and for providing this data to the transaction partner for comparison with his own data, to be confirmed by the transaction partner himself. if the identity verification process is completed, the data is made available to the transaction partner – This also applies if the verification might prove out afterwards sometimes to be unsuccessful.</span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">Recipients or categories of recipients of personal data</span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">No third party transmission during the verification process</span>
                    <div>As part of the verification process, the data is not transferred to third parties, but remains exclusively on the servers of PXL in Switzerland until the identity has been successfully completed. </div>
                  </li>
                  <li>
                    <span class="subheading">Transmission to transaction partners</span>
                    <div>After each successful verification, you will be asked by the App or WebApp whether you agree that the result of the verification and the collected data may be transferred to the transaction partner already known to you and expressly and specifically named again during the verification process for the purpose of comparison and, if necessary, further storage and processing by the transaction partner. After consent has been obtained, PXL will transmit this data to the named transaction partner via a secure connection. From this point on, the transaction partner is solely responsible to you for further processing. Please inform yourself about their data protection regulations before you give your consent.</div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">
                  Place of data processing / transfer of personal data to a third country
                </span>
                <div>
                  PXL processes your personal data exclusively on servers in Switzerland, which
                  are protected by state-of-the-art technical and organizational measures and
                  comply with the SOC 2 standard.
                </div>
                <div>
                  PXL Vision does not transfer your data to third countries, unless you have
                  explicitly agreed to this in a transaction, except for service providers
                  contractually bound to PXL as described in the next paragraph. If a transaction
                  partner is located in a third country that should not be part of the European
                  Economic Area or that does not comply with sufficiently recognized data
                  protection regulations, it is your decision whether you agree with the data
                  protection information provided by the specific transaction partner and whether
                  you consent to the transfer by PXL.
                </div>
                <div>
                  Service providers who process personal data on our behalf outside the European
                  Union (so called third countries) will only be used if there is an "adequacy
                  finding" by the European Commission (Art. 45 DS-GVO), "appropriate guarantees"
                  (Art. 46 DS-GVO) or "internal data protection regulations" (Art. 47 DS-GVO) for
                  this third country at the recipient's end. General information on the Adequacy
                  Decisions can be found at
                  <a href="https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eucountries_de">
                    https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eucountries_de</a>, on
                    the present suitable guarantees at
                  <a href="https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_de">
                    https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_de
                  </a>
                  and on internal data protection provisions at
                  <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/bindingcorporate-rules_de">
                    https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/bindingcorporate-rules_de</a>. For further information, you can contact our data protection officer. In
                  addition, your personal data will be processed in third countries to the extent
                  necessary for the performance of the contract if you have given your consent or
                  if there is a legal obligation.
                </div>
                <div>
                  Employees of our company and service providers who support us in data processing
                  within the scope of commissioned processing (service providers for IT
                  operations, customer service) have access to your personal data to the extent
                  necessary to fulfill the purposes listed below. Any deviations that may exist
                  are expressly described below. In individual cases, we are legally obliged to
                  transmit personal data to authorities (e.g. requests for information from
                  investigating authorities) or natural/legal persons (e.g. to assert claims
                  arising from copyright law).
                </div>
              </li>
              <li>
                <span class="subheading">Duration for which the personal data is stored</span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">Operational System </span>
                    <div>
                      After completing a transaction via MobileApp or WebApp, the data is
                      immediately deleted after download through the transaction partner
                      from the operational system of PXL.</div>
                  </li>
                  <li>
                    <span class="subheading">Storage in the Mobile App </span>
                    <div>
                      Otherwise, the data collected as part of the Mobile App will be stored
                      exclusively on the user's mobile device for as long as the user has
                      installed the App or has not deleted it from the App himself. The data will
                      not be kept at the same time at PXL, but will only be available on the
                      mobile device.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Storage for Machine Learning</span>
                    <div>
                      Please refer to section III. regarding the storage you have consented to for
                      the purposes of Machine Learning.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">Automated decision making</span>
                <div>
                  If the identification and biometric data are successfully matched, an automatic
                  decision is made as to the identity of the user with the documents scanned by
                  him. This decision is not based on personal characteristics of the user, such as
                  age, gender, interests, knowledge, etc., but solely on the algorithmic
                  comparison of the image files and the matching of the identification data.
                  Profiling does not take place.
                </div>
              </li>
              <li>
                <span class="subheading">Your rights</span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">
                      Existence of a right to rectification or deletion of personal data
                      concerning them
                    </span>
                    <div>
                      With regard to the personal data that you have agreed to use for Machine
                      Learning purposes, you have the right to object to and delete your data at
                      any time, which you can exercise by sending an e-mail to
                      <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>. This
                      does not affect the lawfulness of the processing carried out on the
                      basis of your consent until revocation.
                    </div>
                    <div>
                      If you notice an error in the data collected during the verification process
                      via the App or the WebApp, you should cancel the verification process and
                      inform us of this at the following email address:
                      <a href="mailto:support@pxl-vision.com">support@pxl-vision.com</a>
                    </div>
                    <div>
                      After a transaction has been successfully completed, the data on the
                      operative systems of PXL is deleted and a correction is no longer possible.
                      Regarding the data stored for revision purposes according to IV.7.b(also in
                      case of an unsuccessful verification or abortion of the verification
                      process) please also contact the above-mentioned contact details.
                    </div>
                    <div>
                      After collection of the data by the transaction partner after your consent,
                      please contact them directly regarding the correction of the data
                      transmitted to them. PXL no longer has access to this data.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">
                      Existence of a right of access by the person in charge to the personal data
                      concerned
                    </span>
                    <div>
                      In accordance with Art. 15 GDPR, you may at any time request information
                      from PXL about the processing of your personal data via the contact details
                      given above in sections IV.1and IV.2. Please understand that in this case we
                      will take appropriate measures to ensure your identity as the correct
                      recipient of the data information.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">
                      Right to object to processing and the right to data transferability
                    </span>
                    <div>
                      You may at any time object to the processing of your data by contacting PXL
                      using the contact details provided in points IV.1and IV.2above. After the
                      transaction has been completed, no transfer to third parties will take place.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Right of appeal to the supervisory authority </span>
                    <div>
                      They also have the right to appeal to a supervisory authority (Art. 77 GDPR).
                       You can contact the data protection supervisory authority for this purpose.
                      </div>
                  </li>
                  <li>
                    <span class="subheading">Data extract </span>
                    <div>
                      You have the right to receive or transfer personal data concerning you
                      (Art. 20 GDPR). For this purpose, please contact the above-mentioned
                      contact details.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">Secure communication</span>
                <div>
                  For the transmission of confidential information, we recommend contacting us by
                  phone, mail or encrypted contact form. If you contact us by e-mail, social
                  media, messenger services (such as WhatsApp) or other means, we cannot guarantee
                  complete data security.
                </div>
              </li>
              <li>
                <span class="subheading">Changes to the privacy policy</span>
                <div>
                  Since changes in the law or changes in our internal processes may make it
                  necessary to adapt this data protection policy, which we reserve the right to
                  do, you can access the current version as well as older versions of the data
                  protection notice at pxl-vision/privacy.
                </div>
              </li>
            </ol>
            <p>© PXL-Vision AG, October 2020</p>
          </div>
        </li>
      </ol>
    </div>`
} ;
